import Vue from 'vue'
import { parseDate, calculateAge } from '@/utils/common'

Vue.filter('formatDate', function (value) {
  if (value) {
    return parseDate(value)
  } else {
    return ''
  }
})

Vue.filter('formatAge', function (value) {
  return calculateAge(value)
})
