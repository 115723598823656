import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import consts from './utils/constants'
import element from './ui/element'
import './utils/filters'
import 'element-ui/lib/theme-chalk/index.css'
import VueWechatTitle from 'vue-wechat-title'
import vant from './ui/vant'
import 'vant/lib/index.css'
// 引入完整的样式，为后续修改主题颜色做准备
import 'vant/lib/index.less'
import '@/assets/fonts/iconfont.css'

Vue.use(vant)
Vue.use(element)
Vue.use(VueWechatTitle)

Vue.config.productionTip = false
Vue.prototype.$consts = consts

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
