const VERSION = '1.0.2'
const APP_ID = 'wx352b18a917c814ae'
const OSS_ENDPOINT = 'https://xljz.oss-cn-chengdu.aliyuncs.com'
const OPENID_COOKIE_NAME = 'loginId'
const HEAD_PHOTO = 'headPhoto'
const JOB_PHOTO = 'jobPhoto'
const CERT_PHOTO = 'certificatePhoto'
const MEMBER_PRICE = 1.2 // 会员每平米收费价格
const USER_TYPE_NORMAL = 'normal'
const USER_TYPE_MEMBER = 'member'
const USER_TYPE_STAFF = 'staff'
const USER_TYPE_MANAGER = 'manager'
const PREBOOK_TIME_MAP = new Map([
  ['morning', '上午'],
  ['afternoon', '下午']
])
const ORDER_STATUS_MAP = new Map([
  ['waitStaff', '待指定服务人员'],
  ['waitOut', '待上门'],
  ['doing', '进行中'],
  ['obligation', '待付款'],
  ['evaluate', '待评价'],
  ['finish', '已完成'],
  ['canceled', '已取消']
])
const OTHER_ORDER_TYPE_MAP = new Map([
  ['cleaning', '保洁'],
  ['nurse', '保姆'],
  ['monthNurse', '月嫂'],
  ['babyNurse', '育儿嫂'],
  ['woodenFloor', '木地板打蜡'],
  ['floorTiles', '地砖清洗'],
  ['rug', '地毯清洗'],
  ['curtain', '窗帘清洗'],
  ['couch', '沙发清洗'],
  ['glass', '擦玻璃'],
  ['toilet', '卫生间保养'],
  ['kitchen', '厨房整理'],
  ['wardrobe', '衣橱整理'],
  ['wholeHouse', '全屋整理'],
  ['singleDoorRefrigerator', '单开门冰箱清洗'],
  ['doubleDoorRefrigerator', '双开门冰箱清洗'],
  ['sideBySideRefrigerator', '对开门冰箱清洗'],
  ['airConditioning', '立式挂式空调清洗'],
  ['centralAirConditioning', '中央空调清洗'],
  ['halfRangeHood', '油烟机清洗（半拆）'],
  ['allRangeHood', '油烟机清洗（全拆）'],
  ['halfCookTop', '集成灶清洗（半拆）'],
  ['washer', '洗衣机清洗']
])
const STAFF_TYPE_MAP = new Map([
  ['cleaning', '保洁'],
  ['nurse', '保姆'],
  ['monthNurse', '月嫂'],
  ['babyNurse', '育儿嫂'],
  ['furnitureCleaning', '家具清洁'],
  ['applianceCleaning', '家电保养'],
  ['receiveCleaning', '整理收纳']
])
const SEX_TYPE_MAP = new Map([
  ['male', '男'],
  ['female', '女']
])
const LANGUAGE_TYPE_MAP = new Map([
  ['chinese', '普通话'],
  ['english', '英语']
])
const SPECIAL_CLEANING_MAP = new Map([
  ['woodenFloor', {
    name: '木地板打蜡',
    price: '9元/平米 20平米起',
    desc: '地面深度清洁、人工均匀上蜡、机器抛光，可防止地板划伤、腐蚀，延长地板使用寿命。'
  }],
  ['floorTiles', {
    name: '地砖清洗',
    price: '9元/平米 20平米起',
    desc: '全面清洁、除污渍、除油，使地面干净光亮。'
  }],
  ['rug', {
    name: '地毯清洗',
    price: '70元/平米 3平米起',
    desc: '去除地毯表面的灰尘和污垢，全方位清洗'
  }],
  ['toilet', {
    name: '卫生间保养',
    price: '200元/间',
    desc: '卫生间洁具、瓷砖除污渍和水纹，蒸汽无缝杀菌，卫浴设备全面清洁、消毒、抑菌处理。'
  }],
  ['kitchen', {
    name: '厨房整理',
    price: '750元/间',
    desc: '厨房里外全面清洁，蒸汽高温消毒杀菌，天然安全，再把物品分类同时清理不必要的物品，然后合理规划空间，分类摆放和收纳'
  }],
  ['wardrobe', {
    name: '衣橱整理',
    price: '168元/平米 2平米起',
    desc: '断舍离，帮助客户清理不必要的物品，减少混乱状态，恢复空间的秩序和整洁。'
  }],
  ['wholeHouse', {
    name: '全屋整理',
    price: '20元/平米',
    desc: '起价200元。物品分类，同时帮助客户清理不必要的物品，合理空间规划，整齐摆放收纳，恢复空间的秩序和整洁。'
  }],
  ['singleDoorRefrigerator', {
    name: '单开门冰箱清洗',
    price: '180元/个',
    desc: '去除内壁油污、残渣、异味和外壁污渍。建议三个月清洗一次。'
  }],
  ['doubleDoorRefrigerator', {
    name: '双开门冰箱清洗',
    price: '200元/个',
    desc: '500升以下冰箱保养，去除内壁油污、残渣、异味和外壁污渍。'
  }],
  ['sideBySideRefrigerator', {
    name: '对开门冰箱清洗',
    price: '280元/个',
    desc: '500升以上冰箱保养，去除内壁油污、残渣、异味和外壁污渍。'
  }],
  ['airConditioning', {
    name: '立式挂式空调清洗',
    price: '120元/台',
    desc: '专业的换热器深层清洁，保障健康，可节电，延长使用寿命，建议换季清洗一次。'
  }],
  ['centralAirConditioning', {
    name: '中央空调清洗',
    price: '60元/出风口 2个起',
    desc: '出风口清洁消毒，只针对家用中央空调。'
  }],
  ['halfRangeHood', {
    name: '油烟机清洗（半拆）',
    price: '240元/个',
    desc: '油烟机拆洗，面板、内壁、零部件、滤网深层去油污及高温蒸汽消毒处理。'
  }],
  ['allRangeHood', {
    name: '油烟机清洗（全拆）',
    price: '420元/个',
    desc: '油烟机拆洗，面板、内壁、零部件、滤网深层去油污及高温蒸汽消毒处理。'
  }],
  ['halfCookTop', {
    name: '集成灶清洗（半拆）',
    price: '280元/个',
    desc: '拆洗，去油污、使用高温蒸汽消毒处理。'
  }],
  ['washer', {
    name: '洗衣机清洗',
    price: '280元/台',
    desc: '高温拆洗/冲洗。'
  }],
  ['curtain', {
    name: '窗帘清洗',
    price: '9元/斤 15斤起',
    desc: '全方位清洗消毒，免费包拆包安装，可当天取当天挂。'
  }],
  ['couch', {
    name: '沙发清洗',
    price: '60元/座 2座起',
    desc: '全方位清洗消毒，免费包拆包安装。（一座不超过70厘米）'
  }],
  ['glass', {
    name: '擦玻璃',
    price: '室内1.5元/平米 室外15元/平米(不支持高空作业)',
    desc: '玻璃表面清洁，窗框清洁和污渍处理。'
  }]
])
// 家具类型
const FURNITURE_TYPE = ['woodenFloor', 'floorTiles', 'rug', 'curtain', 'couch', 'glass']
// 家电类型
const APPLIANCE_TYPE = ['singleDoorRefrigerator', 'doubleDoorRefrigerator', 'sideBySideRefrigerator', 'airConditioning',
  'centralAirConditioning', 'halfRangeHood', 'allRangeHood', 'halfCookTop', 'washer']
// 整理收纳类型
const RECEIVE_TYPE = ['toilet', 'kitchen', 'wardrobe', 'wholeHouse']

export default {
  APP_ID,
  OSS_ENDPOINT,
  OPENID_COOKIE_NAME,
  HEAD_PHOTO,
  JOB_PHOTO,
  CERT_PHOTO,
  MEMBER_PRICE,
  USER_TYPE_NORMAL,
  USER_TYPE_MEMBER,
  USER_TYPE_STAFF,
  USER_TYPE_MANAGER,
  PREBOOK_TIME_MAP,
  ORDER_STATUS_MAP,
  VERSION,
  OTHER_ORDER_TYPE_MAP,
  STAFF_TYPE_MAP,
  SEX_TYPE_MAP,
  LANGUAGE_TYPE_MAP,
  SPECIAL_CLEANING_MAP,
  FURNITURE_TYPE,
  APPLIANCE_TYPE,
  RECEIVE_TYPE
}
