<template>
  <div id="app">
    <!-- 一级路由的出口 -->
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>

export default {
  name: 'APP'
}
</script>

<style lang="scss">
/* 引入全局样式 */
@import './assets/style/common.scss';
</style>
<style lang="less" scoped></style>
