import { getUserInfo } from '@/api/wechat'

const state = {
  userRole: '',
  userData: {}
}

const mutations = {
  SET_USER_ROLE(state, payload) {
    state.userRole = payload
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload
  }
}

const actions = {
  updateUserInfo({ commit }) {
    getUserInfo().then(res => {
      commit('SET_USER_ROLE', res.userRole)
      commit('SET_USER_DATA', res.userData)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
