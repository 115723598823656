// 按需引入vant组件
import {
  Button, Uploader, Swipe, SwipeItem, Field, Grid, GridItem, NoticeBar, Form, Popup, Area, Checkbox, Toast, Dialog,
  List, PullRefresh, Icon, DatetimePicker, Picker, Radio, RadioGroup, Cell, Tabs, Tab, Row, Col, SubmitBar, GoodsAction,
  GoodsActionIcon, AddressList, AddressEdit, Tabbar, TabbarItem, Lazyload, Image, Loading, GoodsActionButton, Card,
  CheckboxGroup, CellGroup, Tag, Divider, ImagePreview, Sidebar, SidebarItem, Empty
} from 'vant'
import EmptyCom from '@/components/Empty.js'
import ReturnCom from '@/components/Return.js'

// 封装组件
const vant = {
  install: function (Vue) {
    Vue.use(Button)
    Vue.use(Uploader)
    Vue.use(Swipe)
    Vue.use(SwipeItem)
    Vue.use(Field)
    Vue.use(Grid)
    Vue.use(GridItem)
    Vue.use(NoticeBar)
    Vue.use(Form)
    Vue.use(Popup)
    Vue.use(Area)
    Vue.use(Checkbox)
    Vue.use(Toast)
    Vue.use(Dialog)
    Vue.use(List)
    Vue.use(PullRefresh)
    Vue.use(Icon)
    Vue.use(DatetimePicker)
    Vue.use(Picker)
    Vue.use(Radio)
    Vue.use(RadioGroup)
    Vue.use(Cell)
    Vue.use(Tabs)
    Vue.use(Tab)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(SubmitBar)
    Vue.use(GoodsAction)
    Vue.use(GoodsActionIcon)
    Vue.use(AddressList)
    Vue.use(AddressEdit)
    Vue.use(Tabbar)
    Vue.use(TabbarItem)
    Vue.use(Lazyload)
    Vue.use(Image)
    Vue.use(Loading)
    Vue.use(GoodsActionButton)
    Vue.use(Card)
    Vue.use(CheckboxGroup)
    Vue.use(CellGroup)
    Vue.use(Tag)
    Vue.use(Divider)
    Vue.use(ImagePreview)
    Vue.use(Sidebar)
    Vue.use(SidebarItem)
    Vue.use(Empty)
    Vue.use(EmptyCom)
    Vue.use(ReturnCom)
  }
}
// 抛出整体按需引入的组件
export default vant
