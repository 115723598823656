<template>
  <div style="height: 52px">
    <van-goods-action>
      <van-goods-action-icon text="首页" @click="$router.push({path: '/xljz/'})">
        <span slot="icon"><van-icon class-prefix="icon" name="shouye" size="20" /></span>
      </van-goods-action-icon>
      <van-goods-action-button
          type="primary"
          text="返回"
          @click="$router.push({path: toPath})"
      />
    </van-goods-action>
  </div>
</template>

<script>
export default {
  name: 'ReturnCom',
  props: {
    toPath: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
