export function getCookie(name) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const parts = cookies[i].split('=')
    if (parts[0] === name) {
      return parts[1]
    }
  }
  return ''
}

export function parseDate(date) {
  const times = new Date(date)
  // 时间补零
  const zeroFill = (val) => {
    return val < 10 ? `0${val}` : val
  }
  const year = times.getFullYear()
  const month = zeroFill(times.getMonth() + 1)
  const day = zeroFill(times.getDate())
  const hour = zeroFill(times.getHours())
  const minute = zeroFill(times.getMinutes())
  const second = zeroFill(times.getSeconds())
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

export function getOrderStatus(order) {
  if (order.isCancel) {
    return 'canceled'
  }
  if (!order.startTime) {
    return 'waitOut'
  }
  if (order.startTime && !order.endTime) {
    return 'doing'
  }
  if (order.endTime && !order.isPayment) {
    return 'obligation'
  }
  if (order.endTime && order.isPayment && !order.evaluateRate) {
    return 'evaluate'
  }
  if (order.evaluateRate) {
    return 'finish'
  }
}

export function getOtherOrderStatus(order) {
  if (order.isCancel) {
    return 'canceled'
  }
  if (!order.staffOpenId) {
    return 'waitStaff'
  }
  if (!order.startTime) {
    return 'waitOut'
  }
  if (order.startTime && !order.endTime) {
    return 'doing'
  }
  if (order.endTime) {
    return 'finish'
  }
}

export function calculateZodiac(identityId) {
  let year = ''
  if (identityId.length === 18) {
    year = identityId.substring(6, 10)
  } else {
    // 如果身份证号码不是18位，则可能是15位，以下代码适用于15位身份证号码
    if (identityId.length === 15) {
      year = '19' + identityId.substring(6, 8)
    }
  }
  if (year !== '') {
    year = parseInt(year, 10)
    const zodiacAnimals = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']
    return zodiacAnimals[(year % 12)]
  } else {
    return ''
  }
}

export function calculateAge(identityId) {
  const len = (identityId + '').length
  if (len === 0) {
    return 0
  } else {
    if ((len !== 15) && (len !== 18)) {
      return 0
    }
  }
  let strBirthday = ''
  if (len === 18) {
    strBirthday = identityId.substr(6, 4) + '/' + identityId.substr(10, 2) + '/' + identityId.substr(12, 2)
  }
  if (len === 15) {
    strBirthday = '19' + identityId.substr(6, 2) + '/' + identityId.substr(8, 2) + '/' + identityId.substr(10, 2)
  }
  // 时间字符串里，必须是“/”
  const birthDate = new Date(strBirthday)
  const nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export function isExpired(date) {
  return new Date(date).getTime() < new Date().getTime()
}
