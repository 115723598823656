import Vue from 'vue'
import VueRouter from 'vue-router'
import { clearAllReq } from '@/utils/request'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home/Index'),
    meta: {
      title: '官网'
    }
  },
  {
    path: '/xljz',
    redirect: '/xljz/dashboard',
    component: () => import('@/views/InnerPage/Index'),
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/InnerPage/Dashboard'),
        meta: {
          title: '首页',
          isShowFooter: true,
          footerActive: 'dashboard'
        }
      },
      {
        path: 'signAgreement',
        component: () => import('@/views/InnerPage/User/NewMember/SignAgreement'),
        meta: {
          title: '签订协议',
          isShowFooter: true,
          footerActive: 'dashboard'
        }
      },
      {
        path: 'userBaseInfo',
        component: () => import('@/views/InnerPage/User/NewMember/UserBaseInfo'),
        meta: {
          title: '新客户',
          isShowFooter: true,
          footerActive: 'dashboard'
        }
      },
      {
        path: 'orderDetail',
        component: () => import('@/views/InnerPage/Common/OrderDetail'),
        meta: {
          title: '订单详情',
          isShowFooter: true,
          footerActive: 'order'
        }
      },
      {
        path: 'orderList',
        component: () => import('@/views/InnerPage/Common/OrderList'),
        meta: {
          title: '订单列表',
          isShowFooter: true,
          footerActive: 'order'
        }
      },
      {
        path: 'otherOrderDetail',
        component: () => import('@/views/InnerPage/Common/OtherOrderDetail'),
        meta: {
          title: '家政订单详情',
          isShowFooter: true,
          footerActive: 'userCenter'
        }
      },
      {
        path: 'otherOrderList',
        component: () => import('@/views/InnerPage/Common/OtherOrderList'),
        meta: {
          title: '家政订单列表',
          isShowFooter: true,
          footerActive: 'userCenter'
        }
      },
      {
        path: 'reviewAgreement',
        component: () => import('@/views/InnerPage/Staff/ReviewAgreement'),
        meta: {
          title: '协议核实',
          isShowFooter: false
        }
      },
      {
        path: 'member',
        component: () => import('@/views/InnerPage/Manager/Member/Index'),
        meta: {
          title: '会员管理',
          isShowFooter: false
        }
      },
      {
        path: 'userCenter',
        component: () => import('@/views/InnerPage/User/UserCenter/UserCenter'),
        meta: {
          title: '客户中心',
          isShowFooter: true,
          footerActive: 'userCenter'
        }
      },
      {
        path: 'memberOrder',
        component: () => import('@/views/InnerPage/User/NormalOrder/MemberOrder'),
        meta: {
          title: '会员保洁',
          isShowFooter: false
        }
      },
      {
        path: 'memberOrderIntroduce',
        component: () => import('@/views/InnerPage/User/NormalOrder/MemberOrderIntroduce'),
        meta: {
          title: '会员保洁',
          isShowFooter: false
        }
      },
      {
        path: 'normalOrderIntroduce',
        component: () => import('@/views/InnerPage/User/NormalOrder/NormalOrderIntroduce'),
        meta: {
          title: '单次保洁',
          isShowFooter: false
        }
      },
      {
        path: 'normalOrder',
        component: () => import('@/views/InnerPage/User/NormalOrder/NormalOrder'),
        meta: {
          title: '单次保洁',
          isShowFooter: false
        }
      },
      {
        path: 'nurseIntroduce',
        component: () => import('@/views/InnerPage/User/OtherOrder/NurseIntroduce'),
        meta: {
          title: '保姆',
          isShowFooter: false
        }
      },
      {
        path: 'babyNurseIntroduce',
        component: () => import('@/views/InnerPage/User/OtherOrder/BabyNurseIntroduce'),
        meta: {
          title: '育儿嫂',
          isShowFooter: false
        }
      },
      {
        path: 'monthNurseIntroduce',
        component: () => import('@/views/InnerPage/User/OtherOrder/MonthNurseIntroduce'),
        meta: {
          title: '月嫂',
          isShowFooter: false
        }
      },
      {
        path: 'address',
        name: 'address',
        component: () => import('@/views/InnerPage/User/UserCenter/Address'),
        meta: {
          title: '服务地址',
          isShowFooter: false
        }
      },
      {
        path: 'otherOrder',
        component: () => import('@/views/InnerPage/User/OtherOrder/OtherOrder'),
        meta: {
          title: '家政订单',
          isShowFooter: false
        }
      },
      {
        path: 'joinUs',
        component: () => import('@/views/InnerPage/Common/JoinUs'),
        meta: {
          title: '加入我们',
          isShowFooter: true,
          footerActive: 'userCenter'
        }
      },
      {
        path: 'staffApply',
        component: () => import('@/views/InnerPage/Manager/StaffApply/ApplyList'),
        meta: {
          title: '入职申请管理',
          isShowFooter: false
        }
      },
      {
        path: 'staffList',
        component: () => import('@/views/InnerPage/Manager/Staff/StaffList'),
        meta: {
          title: '员工管理',
          isShowFooter: false
        }
      },
      {
        path: 'leaveList',
        component: () => import('@/views/InnerPage/Common/LeaveList'),
        meta: {
          title: '请假管理',
          isShowFooter: false
        }
      },
      {
        path: 'couponList',
        component: () => import('@/views/InnerPage/Manager/Coupon/CouponList'),
        meta: {
          title: '优惠券管理',
          isShowFooter: false
        }
      },
      {
        path: 'userCoupon',
        component: () => import('@/views/InnerPage/User/UserCenter/UserCoupon'),
        meta: {
          title: '优惠券',
          isShowFooter: false
        }
      },
      {
        path: 'obtainCoupon',
        component: () => import('@/views/InnerPage/User/UserCenter/ObtainCoupon'),
        meta: {
          title: '领券中心',
          isShowFooter: false
        }
      },
      {
        path: 'specialCleaningIntroduce',
        component: () => import('@/views/InnerPage/User/OtherOrder/SpecialCleaningIntroduce'),
        meta: {
          title: '专项保洁',
          isShowFooter: true,
          footerActive: 'dashboard'
        }
      },
      {
        path: 'service',
        component: () => import('@/views/InnerPage/User/Service/Index'),
        meta: {
          title: '所有服务',
          isShowFooter: true,
          footerActive: 'service'
        }
      },
      {
        path: 'twoDayOrder',
        component: () => import('@/views/InnerPage/Staff/TwoDayOrder'),
        meta: {
          title: '两日订单',
          isShowFooter: false
        }
      }
    ]
  },
  {
    path: '/memberAgreement',
    component: () => import('@/views/OuterPage/MemberAgreement'),
    meta: {
      title: '会员协议'
    }
  },
  {
    path: '/specialCleaning',
    component: () => import('@/views/OuterPage/SpecialCleaning'),
    meta: {
      title: '专项价格'
    }
  },
  {
    path: '/staffDetail',
    component: () => import('@/views/OuterPage/StaffDetail'),
    meta: {
      title: '员工详情'
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 取消之前页面请求
  clearAllReq()
  next()
})

export default router
