// 按需引入element组件
import { Menu, MenuItem, Submenu, Carousel, CarouselItem, Row, Col } from 'element-ui'

// 封装组件
const element = {
  install: function (Vue) {
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Submenu)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(Row)
    Vue.use(Col)
  }
}
// 抛出整体按需引入的组件
export default element
