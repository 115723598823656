<template>
  <van-empty :description="description">
    <div slot="image">
      <van-icon class-prefix="icon" name="kongzhuangtai" size="170" />
    </div>
  </van-empty>
</template>

<script>
export default {
  name: 'EmptyCom',
  props: {
    description: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
