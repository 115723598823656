// 1、导入axios
import axios from 'axios'
import { Notify } from 'vant'

// 给axios设置默认请求头
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 2、配置
// 创建一个axios实例（创建一个axios的副本）
const request = axios.create({
  baseURL: 'xiaolin',
  timeout: 60000 // 配置超时时间
})

function generateReqKey(config, hash) {
  const { method, url, params, data } = config
  return [method, url, JSON.stringify(params), JSON.stringify(data), hash].join('&')
}
function inReqList(key) {
  return key ? pendingRequest.findIndex(item => item.key === key) : -1
}
// 路由切换时取消之前页面请求
export function clearAllReq() {
  pendingRequest.forEach(pending => {
    if (pending) {
      pending.cancel()
    }
  })
  pendingRequest = []
}
let pendingRequest = []
const { CancelToken } = axios

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    if (process.env.NODE_ENV === 'development') {
      config.headers['xljz-dev'] = 'xljz' // 接口只支持在微信浏览器中调用，为了开发方便，增加开发场景header
    }
    if (config.url.indexOf('aliyuncs.com') !== -1 && config.method === 'put') { // 前端上传oss增加header，和后端返回的签名url保持一致
      config.headers['Content-Type'] = 'image/jpeg'
    }
    if (config.method !== 'get') {
      config.cancelToken = new CancelToken((c) => {
        const reqKey = generateReqKey(config, location.hash)
        const reqIndex = inReqList(reqKey)
        if (reqIndex > -1) {
          // 如果队列中有该请求，则取消之后的请求
          console.log('cancel', config)
          c()
        } else {
          pendingRequest.push({
            key: reqKey,
            cancel: c
          })
        }
      })
    }
    return config
  },
  function (err) {
    // 请求错误做什么
    return Promise.reject(err)
  }
)

request.interceptors.response.use(
  response => {
    // 在请求队列中移除请求
    pendingRequest.splice(inReqList(generateReqKey(response.config, location.hash)), 1)
    const res = response.data
    if (Number(response.status) === 200) {
      return res
    } else {
      const errMsg = res.message || res.msg || res.errorMsg || 'Error'
      Notify({ type: 'danger', message: errMsg })
      return Promise.reject(new Error(errMsg))
    }
  },
  error => {
    // 如果是请求cancel则不再继续处理
    if (!error.config) {
      return new Promise(() => {})
    }
    pendingRequest.splice(inReqList(generateReqKey(error.config, location.hash)), 1)
    console.log('response-err', error) // for debug
    const errorMessage = error.response?.data ? error.response?.data.message : error.message
    Notify({ type: 'danger', message: errorMessage })
    return Promise.reject(error)
  }
)
// 3、导出
export default request
