import request from '@/utils/request'

export function getUserOpenId(params) {
  return request({
    method: 'GET',
    url: '/wechat/openId',
    params: params
  })
}
export function getUserInfo() {
  return request({
    method: 'GET',
    url: '/wechat/userRole'
  })
}
export function getDebugUserOpenId(params) {
  return request({
    method: 'GET',
    url: '/wechat/debugOpenId',
    params: params
  })
}
export function getUrlSignature(payload) {
  return request({
    method: 'POST',
    url: '/wechat/getUrlSignature',
    data: payload
  })
}
export function getStaffDetail(params) {
  return request({
    method: 'GET',
    url: '/wechat/staff/detail',
    params: params
  })
}
